<!--
 * @Descripttion: 中英注册组件
-->

<template>
  <!-- 弹窗注册 -->
  <el-dialog v-model="isShowRegister"
             width="500px"
             :show-close="false"
             :close-on-click-modal="false"
             custom-class="pop-float-el-dialog">
    <!-- 中文注册 -->
    <div v-if="language == 'CN'"
         class="popup-conetnt">
      <img class="popup-close"
           @click="hideRegister"
           src="../../assets/img/sd-icon-close.png" />
      <p class="popup-tip">注册</p>
      <div class="popup-login">
        <img class="img"
             src="../../assets/img/sd-logo2.png"
             alt="logo" />

        <div class="flex flex-aic">
          <div class="identification">*</div>
          <input v-model="cn.name"
                 class="login-in"
                 placeholder="姓名" />
        </div>

        <div style="padding-left: 18px">
          <input v-model="cn.company"
                 class="login-item login-in"
                 placeholder="请输入公司名称" />
        </div>

        <div class="flex flex-aic login-item">
          <div class="identification">*</div>
          <input class="login-in"
                 v-model="cn.phone"
                 maxlength="11"
                 placeholder="请输入手机号"
                 onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                 onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}" />
        </div>

        <div class="between login-item">
          <div class="flex flex-aic">
            <div class="identification">*</div>
            <input class="flex1 login-in"
                   v-model="cn.code"
                   placeholder="请输入短信验证码" />
          </div>
          <button class="getcode-btn"
                  v-show="showCode"
                  @click="getCode"
                  :disabled="isDisabled">
            获取验证码
          </button>
          <span class="getcode-second"
                v-show="!showCode">{{ count }}秒后重新获取</span>
        </div>
        <div class="flex flex-aic authorization">
          <div>
            <el-checkbox v-model="checkedCn"
                         size="large" />
          </div>
          <div class="ml10">我已阅读并同意《<span @click="privacyAgreement()"
                  style="cursor: pointer;">隐私声明</span>》条款内容</div>
        </div>
        <button class="to-register-btn"
                @click="handLogin">注册</button>
        <p class="to-sign-up login-item">
          我有账号，<span @click="showLogin">去登录>></span>
        </p>
      </div>
    </div>

    <!--  英文注册 -->
    <div v-if="language == 'EN'"
         class="popup-conetnt">
      <img class="popup-close"
           @click="hideRegister"
           src="../../assets/img/sd-icon-close.png" />
      <p class="popup-tip">register</p>
      <div class="popup-login">
        <img class="img"
             src="../../assets/img/sd-logo2.png"
             alt="logo" />

        <div class="flex flex-aic">
          <div class="identification">*</div>
          <input class="login-in"
                 v-model="en.name"
                 placeholder="Name" />
        </div>

        <div style="padding-left: 18px">
          <input class="login-item login-in"
                 v-model="en.company"
                 placeholder="Company Name" />
        </div>

        <div class="flex flex-aic">
          <div class="identification">*</div>
          <el-input class="login-item login-in"
                    v-model="en.password"
                    type="password"
                    placeholder="Password"
                    show-password />
        </div>

        <div class="flex flex-aic login-item">
          <div class="identification">*</div>
          <input class="login-in"
                 type="password"
                 v-model="en.confirmPassword"
                 placeholder="Confirm Password" />
        </div>

        <div class="flex flex-aic login-item">
          <div class="identification">*</div>
          <input class="login-in"
                 v-model="en.email"
                 placeholder="Mail" />
        </div>

        <div class="between login-item">
          <div class="flex flex-aic">
            <div class="identification">*</div>
            <input class="flex1 login-in"
                   v-model="en.code"
                   placeholder="Code" />
          </div>
          <button class="getcode-btn"
                  v-show="showCode"
                  @click="getCodeEn"
                  :disabled="isDisabled">
            Get Code
          </button>
          <span class="getcode-second"
                v-show="!showCode"> {{count}} seconds</span>
        </div>
        <div class="flex flex-aic authorization">
          <div>
            <el-checkbox v-model="checkedCn"
                         size="large" />
          </div>
          <div class="ml10">I have read and agree 《<span @click="privacyAgreement()"
                  style="cursor: pointer;">Privacy Statement</span>》 terms and conditions</div>
        </div>
        <button class="to-register-btn"
                @click="handLoginEn">register</button>
        <p class="to-sign-up login-item">
          have an account，<span @click="showLogin">Log in>></span>
        </p>
      </div>
    </div>

  </el-dialog>

</template>

<script>
import { register, send, regemail, send_email } from "../../api/common"; // api
import { ElMessage } from "element-plus";
export default {
  props: {
    isShowRegister: { type: Boolean, default: false },//隐藏现实
    language: { type: String, default: 'EN' },//语言
    lang: { type: Array, default: [] },
  },
  data () {
    return {
      cn: {
        name: "",//姓名
        phone: "",//手机号
        company: "",//公司名
        code: "",//验证码
        channel_id: this.getQueryVariable('channel_id')
      },
      en: {
        name: "",//姓名
        company: "",//公司名
        password: "",//密码
        confirmPassword: "",//确认密码
        email: "",//邮箱
        code: "",//验证码
        channel_id: this.getQueryVariable('channel_id')
      },
      showCode: true,
      isDisabled: false,
      count: "",
      checkedCn: false,
    };
  },
  watch: {},
  mounted () {

  },
  methods: {
    getQueryVariable (variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
      }
      return (false);
    },
    privacyAgreement () {
      this.$parent.privacyAgreement = true
    },
    // 关闭注册弹窗
    hideRegister () {
      this.reset();
      this.$emit("hideRegister");
    },
    // 关闭注册，打开登录
    showLogin () {
      this.$emit("hideRegister");
      this.$emit("showLogin");
    },
    // 重置
    reset () {
      this.cn = {
        name: "",//姓名
        phone: "",//手机号
        company: "",//公司名
        code: "",//验证码
      };
      this.en = {
        name: "",//姓名
        company: "",//公司名
        password: "",//密码
        confirmPassword: "",//确认密码
        email: "",//邮箱
        code: "",//验证码
      };
      this.showCode = true;
      this.isDisabled = false;
      this.count = "";
      this.checkedCn = false;
    },
    // 获取英文验证码
    // /api/send_email
    getCodeEn () {
      if (!this.en.email) {
        ElMessage({
          message: this.lang[10],
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      this.isDisabled = true;
      let params = {
        email: this.en.email,
      };
      if (!this.timer) {
        send_email(params).then((res) => {
          console.log(res, "res");
          if (res.code == 0) {
            this.count = 60;
            this.showCode = false;
            ElMessage({
              message: this.lang[11],
              type: "success",
              center: true,
              offset: 100,
            });
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= 60) {
                this.count--;
              } else {
                this.showCode = true;
                clearInterval(this.timer);
                this.timer = null;
                this.isDisabled = false;
              }
            }, 1000);
          } else {
            this.isDisabled = false;
          }
        });
      }
    },
    // 获取手机验证码
    getCode () {
      if (!this.cn.phone) {
        ElMessage({
          message: "手机号不能为空",
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      this.isDisabled = true;
      let params = {
        phone: this.cn.phone,
      };
      if (!this.timer) {
        send(params).then((res) => {
          console.log(res, "res");
          if (res.code == 0) {
            this.count = 60;
            this.showCode = false;
            ElMessage({
              message: "短信验证码已发送",
              type: "success",
              center: true,
              offset: 100,
            });
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= 60) {
                this.count--;
              } else {
                this.showCode = true;
                clearInterval(this.timer);
                this.timer = null;
                this.isDisabled = false;
              }
            }, 1000);
          } else {
            this.isDisabled = false;
          }
        });
      }
    },
    // 英文注册
    handLoginEn () {
      let that = this;
      if (!that.checkedCn) {
        ElMessage({
          message: this.lang[17],
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      if (!that.en.name) {
        ElMessage({
          message: this.lang[21],
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      if (!that.en.password) {
        ElMessage({
          message: this.lang[14],
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      if (that.en.confirmPassword != that.en.password) {
        ElMessage({
          message: this.lang[14],
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      if (!that.en.email) {
        ElMessage({
          message: this.lang[10],
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      if (!that.en.code) {
        ElMessage({
          message: this.lang[13],
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      let params = {
        name: that.en.name,
        company: that.en.company,
        password: that.en.password,
        email: that.en.email,
        code: that.en.code,
        channel_id: this.getQueryVariable('channel_id')
      };
      regemail(params).then((res) => {
        if (res.code == 0) {
          setTimeout(() => {
            that.hideRegister();
          }, 300);
          ElMessage({
            message: this.lang[19],
            type: "success",
            center: true,
            offset: 100,
          });
          // that.reset();
          window.localStorage.setItem("userinfo", JSON.stringify(res.body));
          this.$emit('getUserInfo', res.body);
          this.$parent.isShowPay = res.body.is_pay

        } else {
          ElMessage({
            message: res.msg,
            type: "error",
            center: true,
            offset: 100,
          });
        }
      });
    },


    // 中文注册
    handLogin () {
      if (!this.checkedCn) {
        ElMessage({
          message: "请阅读并同意隐私声明",
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      if (!this.cn.name) {
        ElMessage({
          message: "姓名不能为空",
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      if (!this.cn.phone) {
        ElMessage({
          message: "手机号不能为空",
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      if (!this.cn.code) {
        ElMessage({
          message: "短信验证码不能为空",
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      let params = this.cn;
      register(params).then((res) => {
        if (res.code == 0) {
          setTimeout(() => {
            this.hideRegister();
          }, 300);
          window.localStorage.setItem("userinfo", JSON.stringify(res.body));
          this.$emit('getUserInfo', res.body);
          this.$parent.isShowPay = res.body.is_pay
          ElMessage({
            message: "注册成功",
            type: "success",
            center: true,
            offset: 100,
          });
          this.reset();
          // this.showLogin();
        } else {
          ElMessage({
            message: res.msg,
            type: "error",
            center: true,
            offset: 100,
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner {
  border: none;
  height: 100%;
  width: 100%;
  padding: 0;
  background: rgba(0, 0, 0, 0);
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
/* 弹窗-登录/注册 */
.popup-login {
  .ml10 {
    margin-left: 10px;
  }

  padding: 0 70px !important;

  .img {
    width: 280px;
    height: 30px;
    margin: 0 auto 29px;
  }

  .login-item {
    margin-top: 20px;
  }

  .login-in {
    height: 48px;
    background: #f3f3f3;
    border: 1px solid #1ea78d;
    // border: none;
    // background: url("../assets/img/border-2.png") no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
    line-height: 48px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000;
    padding: 0 20px;
    width: 100%;
  }

  .getcode-btn {
    height: 48px;
    background: #1ea78d;
    border-radius: 10px;
    margin-left: 10px;
    padding: 0 17px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border: none;
    // line-height: 58px;
  }

  .getcode-second {
    // margin-left: 0;
    padding: 0 17px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999;
    // line-height: 58px;
    flex-shrink: 0;
  }

  .code-img {
    // width: 92px;
    height: 48px;
    background: #f3f3f3;
    border-radius: 10px;
    margin-left: 20px;
  }

  .login-info-error {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #e02020;

    img {
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }
  }

  .to-login-btn {
    width: 100%;
    height: 48px;
    background: #1ea78d;
    border-radius: 10px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border: none;
    margin-top: 68px;
  }

  .to-register-btn {
    width: 100%;
    height: 48px;
    background: #1ea78d;
    border-radius: 10px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border: none;
    margin-top: 38px;
  }

  .to-sign-up {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
    text-align: right;

    span {
      color: #1ea78d;
      cursor: pointer;
    }
  }

  .identification {
    font-size: 16px;
    color: #d50808;
    margin-right: 10px;
  }

  .authorization {
    margin-top: 18px;
    color: #666666;
    font-size: 12px;
    padding: 0 15px;
  }
}
</style>
