//src/api/common.js
import request from '/utils/request'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

// 获取首页数据
export function index (data) {
  return request({
    url: '/api/index?lang=' + data,
    method: 'get'
  })
}
export function orderList (id) {
  return request({
    url: '/api/order/list?user_id=' + id,
    method: 'get',
  })
}
export function downloadList () {
  return request({
    url: '/api/download/list',
    method: 'get',
  })
}




// 中文注册
export function register (data) {
  return request({
    url: '/api/register',
    method: 'post',
    data
  })
}
// 英文注册
export function regemail (data) {
  return request({
    url: '/api/regemail',
    method: 'post',
    data
  })
}

// 中文登录
export function login (data) {
  return request({
    url: '/api/login',
    method: 'post',
    data
  })
}
// 英文登录
export function login_email (data) {
  return request({
    url: '/api/login_email',
    method: 'post',
    data
  })
}

// 中文获取短信验证码
export function send (data) {
  return request({
    url: '/api/send',
    method: 'post',
    data
  })
}
// 英文文获取验证码
export function send_email (data) {
  return request({
    url: '/api/send_email',
    method: 'post',
    data
  })
}

// 找回密码
export function retrieve (data) {
  return request({
    url: '/api/retrieve',
    method: 'post',
    data
  })
}


// Paypal支付
export function paypal (data) {
  return request({
    url: '/api/pay/paypal',
    method: 'post',
    data
  })
}

// 微信支付
export function wechat (data) {
  return request({
    url: '/api/pay/wechat',
    method: 'post',
    data
  })
}

// 支付宝支付
export function alipay (data) {
  return request({
    url: '/api/pay/alipay',
    method: 'post',
    data
  })
}

//是否已支付
export function isPay (data, id) {
  if (!id) {
    id = 0
  }
  return request({
    url: '/api/pay/is_pay?user_id=' + data + '&order_id=' + id,
    method: 'get',
  })
}




// 单端登录
export function login_time (data) {
  return request({
    url: '/api/login_time?user_id=' + data,
    method: 'get'
  })
}
