<!--
 * @Descripttion: 中英登录组件
-->

<template>
  <!-- 弹窗 -->
  <el-dialog v-model="isShowLogin"
             width="500px"
             :show-close="false"
             :close-on-click-modal="false"
             custom-class="pop-float-el-dialog">
    <!-- 中文登录 -->
    <div v-if="language == 'CN'"
         class="popup-conetnt">
      <img class="popup-close"
           @click="hideLogin"
           src="../../assets/img/sd-icon-close.png" />
      <p class="popup-tip">登录</p>
      <div class="popup-login">
        <img class="img"
             src="../../assets/img/sd-logo2.png"
             alt="logo" />
        <input class="login-item login-in"
               v-model="cn.phone"
               maxlength="11"
               placeholder="请输入手机号"
               @keyup.enter.native="handLogin"
               onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
               onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}" />
        <div class="between login-item">
          <input class="flex1 login-in"
                 v-model="cn.code"
                 @keyup.enter.native="handLogin"
                 placeholder="请输入短信验证码" />
          <button class="getcode-btn"
                  v-show="showCode"
                  @click="getCode"
                  :disabled="isDisabled">
            获取验证码
          </button>
          <span class="getcode-second"
                v-show="!showCode">{{ count }}秒后重新获取</span>
        </div>

        <button class="to-login-btn"
                @click="handLogin">登录</button>
        <p class="to-sign-up login-item"
           @click="showRegister(1)">
          没有账号，<span>去注册>></span>
        </p>
      </div>
    </div>

    <!-- 英文登录 -->
    <div v-if="language == 'EN'"
         class="popup-conetnt">
      <img class="popup-close"
           @click="hideLogin"
           src="../../assets/img/sd-icon-close.png" />
      <p class="popup-tip">Log in</p>
      <div class="popup-login">
        <img class="img"
             src="../../assets/img/sd-logo2.png"
             alt="logo" />
        <input class="login-item login-in"
               v-model="en.email"
               placeholder="Email" />
        <div class="between">
          <el-input class="login-item login-in"
                    v-model="en.password"
                    type="password"
                    placeholder="Password"
                    placeholder-color="#f00"
                    show-password />
        </div>
        <div class="forgot-password"
             @click="showRegister(2)">Forgot password</div>
        <button class="to-login-btn"
                @click="handLoginEn">Log in</button>
        <p class="to-sign-up login-item"
           @click="showRegister(1)">
          No account，<span>Register>></span>
        </p>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { login, send, login_email } from "../../api/common"; // api
import { ElMessage } from "element-plus";
export default {
  props: {
    isShowLogin: { type: Boolean, default: false }, //隐藏现实
    language: { type: String, default: "EN" }, //语言默认中文
    lang: { type: Array, default: [] },
  },
  data () {
    return {
      cn: {
        phone: "",//手机号
        code: "",//验证码
      },
      en: {
        email: "",//邮箱
        password: "",//密码
      },
      showCode: true,
      isDisabled: false,
      count: "",
    };
  },
  watch: {},
  mounted () { },
  methods: {
    // 关闭登录弹窗
    hideLogin () {
      this.reset();
      this.$emit("hideLogin");
    },
    // 关闭登录，打开注册或忘记密码1注册2忘记密码
    showRegister (type) {
      this.$emit("hideLogin");
      this.$emit("showRegister", type);
    },
    // 重置
    reset () {
      this.cn = {
        phone: "",//手机号
        code: "",//验证码
      };
      this.en = {
        email: "",//邮箱
        password: "",//密码
      };
      this.showCode = true;
      this.isDisabled = false;
      this.count = "";
    },

    // 获取手机验证码
    getCode () {
      if (!this.cn.phone) {
        ElMessage({
          message: "手机号不能为空",
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      this.isDisabled = true;
      let params = {
        phone: this.cn.phone,
      };
      if (!this.timer) {
        send(params).then((res) => {
          if (res.code == 0) {
            this.count = 60;
            this.showCode = false;
            ElMessage({
              message: "短信验证码已发送",
              type: "success",
              center: true,
              offset: 100,
            });
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= 60) {
                this.count--;
              } else {
                this.showCode = true;
                clearInterval(this.timer);
                this.timer = null;
                this.isDisabled = false;
              }
            }, 1000);
          } else {
            this.isDisabled = false;
          }
        });
      }
    },
    // 英文登录
    handLoginEn () {
      if (!this.en.email) {
        ElMessage({
          message: this.lang[10],
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      if (!this.en.password) {
        ElMessage({
          message: this.lang[14],
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      let params = {
        email: this.en.email,
        password: this.en.password,
      };
      login_email(params).then((res) => {
        if (res.code == 0) {
          window.localStorage.setItem("userinfo", JSON.stringify(res.body));
          setTimeout(() => {
            this.hideLogin();
          }, 300);
          ElMessage({
            message: this.lang[19],
            type: "success",
            center: true,
            offset: 100,
          });
          this.$emit('getUserInfo', res.body);
          this.$parent.isShowPay = res.body.is_pay
        } else {
          ElMessage({
            message: res.msg,
            type: "error",
            center: true,
            offset: 100,
          });
        }
      });
    },
    // 中文登录
    handLogin () {
      if (!this.cn.phone) {
        ElMessage({
          message: "手机号不能为空",
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      if (!this.cn.code) {
        ElMessage({
          message: "短信验证码不能为空",
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      let params = {
        phone: this.cn.phone,
        code: this.cn.code,
      };
      login(params).then((res) => {
        if (res.code == 0) {
          window.localStorage.setItem("userinfo", JSON.stringify(res.body));
          setTimeout(() => {
            this.hideLogin();
          }, 300);
          ElMessage({
            message: "登录成功",
            type: "success",
            center: true,
            offset: 100,
          });
          this.$emit('getUserInfo', res.body);
          this.$parent.isShowPay = res.body.is_pay
        } else {
          ElMessage({
            message: res.msg,
            type: "error",
            center: true,
            offset: 100,
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner {
  border: none;
  height: 100%;
  width: 100%;
  padding: 0;
  background: rgba(0, 0, 0, 0);
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

/* 弹窗-登录/注册 */
.popup-login {
  padding: 0 70px !important;

  .img {
    width: 280px;
    height: 30px;
    margin: 0 auto 29px;
  }

  .login-item {
    margin-top: 20px;
  }

  .login-in {
    height: 48px;
    background: #f3f3f3;
    border: 1px solid #1ea78d;
    // border: none;
    // background: url("../assets/img/border-2.png") no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
    line-height: 48px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000;
    padding: 0 20px;
    width: 100%;
  }

  .getcode-btn {
    height: 48px;
    background: #1ea78d;
    border-radius: 10px;
    margin-left: 10px;
    padding: 0 17px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border: none;
    // line-height: 58px;
  }

  .getcode-second {
    margin-left: 20px;
    padding: 0 17px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999;
    line-height: 58px;
  }

  .code-img {
    // width: 92px;
    height: 48px;
    background: #f3f3f3;
    border-radius: 10px;
    margin-left: 20px;
  }

  .login-info-error {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #e02020;

    img {
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }
  }

  .to-login-btn {
    width: 100%;
    height: 48px;
    background: #1ea78d;
    border-radius: 10px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border: none;
    margin-top: 68px;
  }

  .to-register-btn {
    width: 100%;
    height: 48px;
    background: #1ea78d;
    border-radius: 10px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border: none;
    margin-top: 38px;
  }

  .to-sign-up {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
    text-align: right;

    span {
      color: #1ea78d;
      cursor: pointer;
    }
  }

  .identification {
    font-size: 16px;
    color: #d50808;
    margin-right: 10px;
  }

  .forgot-password {
    font-size: 12px;
    color: #1ea78d;
    text-align: right;
    margin-top: 14px;
    cursor: pointer;
  }

  .authorization {
    margin-top: 18px;
    color: #666666;
  }
}
</style>
