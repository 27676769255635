<!--
 * @Descripttion: 忘记密码组件
-->

<template>
  <!-- 弹窗 -->
  <el-dialog v-model="isShowForgotPassword" width="500px" :show-close="false" :close-on-click-modal="false"
    custom-class="pop-float-el-dialog">

    <!--  英文忘记密码 -->
    <div class="popup-conetnt">
      <img class="popup-close" @click="hideForgotPassword" src="../../assets/img/sd-icon-close.png" />
      <p class="popup-tip">Retrieve Password</p>
      <div class="popup-login">
        <img class="img" src="../../assets/img/sd-logo2.png" alt="logo" />

        <div class="flex flex-aic">
          <input class="login-in" v-model="en.email" placeholder="Email" />
        </div>

        <div class="between login-item">
          <div class="flex flex-aic">
            <input class="flex1 login-in" v-model="en.code" placeholder="Code" />
          </div>
          <button class="getcode-btn" v-show="showCode" @click="getCodeEn" :disabled="isDisabled">
            Get Code
          </button>
          <span class="getcode-second" v-show="!showCode"> {{ count }} seconds</span>
        </div>


        <div class="flex flex-aic">
          <el-input class="login-item login-in" v-model="en.password" type="password" placeholder="Password"
            show-password />
        </div>

        <div class="flex flex-aic login-item">
          <input class="login-in" type="password" v-model="en.confirmPassword" placeholder="Confirm Password" />
        </div>

        <button class="to-register-btn" @click="forgotPassword">Reset</button>
      </div>
    </div>

  </el-dialog>


</template>

<script>
import { retrieve, send_email } from "../../api/common"; // api
import { ElMessage } from "element-plus";
export default {
  props: {
    isShowForgotPassword: { type: Boolean, default: false },//隐藏现实
    lang: { type: Array, default:[] }, 
  },
  data() {
    return {
      en: {
        email: "",//邮箱
        code: "",//验证码
        password: "",//密码
        confirmPassword: "",//确认密码
      },
      showCode: true,
      isDisabled: false,
      count: "",
    };
  },
  watch: {},
  mounted() { },
  methods: {
        // 关闭忘记密码弹窗
    hideForgotPassword() {
      this.reset();
      this.$emit("hideForgotPassword");
    },

    // 关闭修改密码，打开登录
    showLogin() {
      this.$emit("hideForgotPassword");
      this.$emit("showLogin");
    },

    // 重置
    reset() {
      this.en = {
        email: "",//邮箱
        code: "",//验证码
        password: "",//密码
        confirmPassword: "",//确认密码
      };
      this.showCode = true;
      this.isDisabled = false;
      this.count = "";
    },

    // 获取英文验证码
    getCodeEn() {
      if (!this.en.email) {
        ElMessage({
          message: this.lang[10],
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      this.isDisabled = true;
      let params = {
        email: this.en.email,
      };
      if (!this.timer) {
        send_email(params).then((res) => {
          console.log(res, "res");
          if (res.code == 0) {
            this.count = 60;
            this.showCode = false;
            ElMessage({
              message: this.lang[11],
              type: "success",
              center: true,
              offset: 100,
            });
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= 60) {
                this.count--;
              } else {
                this.showCode = true;
                clearInterval(this.timer);
                this.timer = null;
                this.isDisabled = false;
              }
            }, 1000);
          } else {
            this.isDisabled = false;
          }
        });
      }
    },

    // 英文修改密码
    forgotPassword() {
      let that = this;
      if (!that.en.email) {
        ElMessage({
          message: this.lang[10],
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      if (!that.en.code) {
        ElMessage({
          message: this.lang[13],
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }
      if (!that.en.password) {
        ElMessage({
          message: this.lang[14],
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }

      if (that.en.confirmPassword != that.en.password) {
        ElMessage({
          message: this.lang[15],
          type: "error",
          center: true,
          offset: 100,
        });
        return false;
      }

      let params = {
        email: that.en.email,
        code: that.en.code,
        password: that.en.password,
      };

      retrieve(params).then((res) => {
        if (res.code == 0) {
          setTimeout(() => {
            that.hideLogin();
          }, 300);
          ElMessage({
          message: this.lang[4],
            type: "success",
            center: true,
            offset: 100,
          });
          that.reset();
          setTimeout(() => {
              that.showLogin();
          }, 1000);
        } else {
          ElMessage({
            message: res.msg,
            type: "error",
            center: true,
            offset: 100,
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input__inner {
  border: none;
  height: 100%;
  width: 100%;
  padding: 0;
  background: rgba(0, 0, 0, 0);
  font-size: 14px;
  font-weight: 500;
  color: #000;

}

/* 弹窗-登录/注册 */
.popup-login {
  .ml10 {
    margin-left: 10px;
  }

  padding: 0 70px !important;

  .img {
    width: 280px;
    height: 30px;
    margin: 0 auto 29px;
  }

  .login-item {
    margin-top: 20px;
  }

  .login-in {
    height: 48px;
    background: #f3f3f3;
    border: 1px solid #1ea78d;
    // border: none;
    // background: url("../assets/img/border-2.png") no-repeat;
    background-size: 100% 100%;
    border-radius: 10px;
    line-height: 48px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #000;
    padding: 0 20px;
    width: 100%;
  }

  .getcode-btn {
    height: 48px;
    background: #1ea78d;
    border-radius: 10px;
    margin-left: 10px;
    padding: 0 17px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border: none;
    // line-height: 58px;
  }

  .getcode-second {
    margin-left: 20px;
    padding: 0 17px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999;
    line-height: 58px;
    flex-shrink: 0;
  }

  .code-img {
    // width: 92px;
    height: 48px;
    background: #f3f3f3;
    border-radius: 10px;
    margin-left: 20px;
  }

  .login-info-error {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #e02020;

    img {
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }
  }

  .to-login-btn {
    width: 100%;
    height: 48px;
    background: #1ea78d;
    border-radius: 10px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border: none;
    margin-top: 68px;
  }

  .to-register-btn {
    width: 100%;
    height: 48px;
    background: #1ea78d;
    border-radius: 10px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border: none;
    margin-top: 38px;
  }

  .to-sign-up {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
    text-align: right;

    span {
      color: #1ea78d;
      cursor: pointer;
    }
  }

  .identification {
    font-size: 16px;
    color: #d50808;
    margin-right: 10px;
  }

  .authorization {
    margin-top: 18px;
    color: #666666;
  }
}
</style>
